<template>
  <div>
    <b-card>
      <h5 class="text-capitalize mb-75">
        Hospitalisation and Complication Information
      </h5>
      <!-- BODY -->
      <validation-observer ref="refFormCDECareInfo">
        <!-- form -->
        <b-form
          :style="{ height: trHeight }"
          class="repeater-form mt-2"
        >
          <b-row ref="row">
            <b-col md="10">
              <b-form-group
                label="In the last 3 months did you see an Endocrinologist or Credentialled Diabetes Educator?*"
                label-for="care_team_visit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="team visit"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.care_team"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="careTeamList"
                    :clearable="false"
                    input-id="added_by"
                    aria-required="true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row ref="row">
            <b-col md="10">
              <b-form-group
                label="Diabetes Complications before FreeStyle Libre®?"
                label-for="diabetes_complications"
              >
                <!-- <validation-provider
                  #default="{errors}"
                  name="diabetes complications"
                  rules="required"
                > -->
                <v-select
                  v-model="userLocal.diabetes_complications"
                  aria-required="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="diabetesComplicationsList"
                  :clearable="false"
                  multiple
                  input-id="diabetes_complications"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row ref="row">
            <b-col sm="10">
              <b-form-group
                label="Number of Hospitalisations - Before using FreeStyle Libre® *"
                label-for="number_of_hospitalisation"
              >
                <validation-provider
                  #default="{errors}"
                  name="number of hospitalisation"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.number_of_hospitalisation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="numericalOptionList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="number_of_hospitalisation"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row ref="row">
            <b-col md="10">
              <b-form-group
                label="Hospitalisation Details"
                label-for="hospitalisation_details"
              >
                <!-- <validation-provider
                  #default="{errors}"
                  name="hospitalisation details"
                  rules="required"
                > -->
                <v-select
                  v-model="userLocal.hospitalisation_details"
                  multiple
                  aria-required="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="hospitalisationList"
                  :clearable="false"
                  input-id="hospitalisation_details"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
            <b-col md="10">
              <b-form-group
                label="Hospitalisation Detail Comments"
                label-for="hospitalisation_details_comments"
              >
                <!-- <validation-provider
                  #default="{errors}"
                  name="Hospitalisation detail comments"
                  rules="required"
                > -->
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.hospitalisation_details_comments"
                  placeholder="Add any comments regarding hospitalisation"
                  rows="3"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group
                label="Recent changes in diabetes therapy*"
                label-for="recent_changes_in_diabetes_therapy"
              >
                <validation-provider
                  #default="{errors}"
                  name="recent changes in diabetes therapy"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="userLocal.recent_changes_in_diabetes_therapy"
                    placeholder=""
                    rows="3"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group
                label="Patient Comments"
                label-for="patient_comments"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.patient_comments"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group
                label="CDE Comments"
                label-for="cde_comments"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="userLocal.healthcare_professional_comments"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- form -->
        <b-form
          :style="{ height: trHeight }"
          class="repeater-form mt-2"
        >
          <b-row ref="row">
            <b-col sm="6">
              <b-form-group
                label="Is FreeStyle Libre 2 recommended? If so, what NDSS form was completed and sent? *"
                label-for="if_freestyle_libre_ndss_approved"
              >
                <validation-provider
                  #default="{errors}"
                  name="ndss_approved_criteria"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.ndss_approved_criteria"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="NdssApprovedOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="ndss_approved_criteria"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Reason for recommending FreeStyle Libre® *"
                label-for="freestyle_libre_reason"
              >
                <validation-provider
                  #default="{errors}"
                  name="Reason for recommending FreeStyle Libre®"
                  rules="required"
                >
                  <v-select
                    v-model="userLocal.freestyle_libre_reason"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="libreReasonList"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="freestyle_libre_reason"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="
                userLocal.freestyle_libre_reason ===
                  'b3f1518d-5b2a-431e-a50d-90d34be3f693'
              "
              sm="6"
            >
              <b-form-group
                label="Please specify a reason if Other *"
                label-for="other_freestyle_libre_reason"
              >
                <validation-provider
                  #default="{errors}"
                  name="other reason"
                  rules="required"
                >
                  <b-form-input
                    id="other_freestyle_libre_reason"
                    v-model="userLocal.other_freestyle_libre_reason"

                    placeholder="Please specify a reason for recommending FreeStyle Libre®"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      patientPreAssignedDetail: [],
      medicationSelected: [],
      medicationBrandSelected: [],
      careTeamList: [],
      hospitalisationList: [],
      diabetesComplicationsList: [],
      required,
      alphaNum,
      nameState: null,
      patientData: null,
      libreReasonList: [],
      visitLocal: {
        type: Object,
        default: () => {},
      },
      nextTodoId: 2,
      number_of_previous_hospitalisations: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3 or more', value: '3 or more' },
      ],
      userLocal: {
        type: Object,
        default: () => {},
      },
      NdssApprovedOptions: [
        { label: 'T1 < 21 years', value: 'T1 < 21 years' },
        {
          label: 'T1 > 21 years + concession',
          value: 'T1 > 21 years + concession',
        },
        {
          label: 'T1 planning pregnancy/pregnant/post pregnancy',
          value: 'T1 planning pregnancy/pregnant/post pregnancy',
        },
        {
          label: 'Other diabetes > 21 years',
          value: 'Other diabetes > 21 years',
        },
      ],
      numericalOptionList: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: 'Don\'t know/Depends', value: 'Don\'t know/Depends' },
      ],
    }
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetchCareTeamProfList()
    this.fetchHospitalisationList()
    this.fetchDiabetesComplicationList()
    this.fetchLibreReasonList()
    if (this.userData.last_visit !== null) {
      this.userLocal = this.userData.last_visit
      this.userLocal.freestyle_libre_reason = this.userData.glucose_libre_data.freestyle_libre_reason
      this.userLocal.ndss_approved_criteria = this.userData.glucose_libre_data.ndss_approved_criteria
    } else {
      this.userLocal = {
        care_team: null,
        diabetes_complications: null,
        number_of_hospitalisation: null,
      }
    }
    this.userLocal.user_pub_id = this.userData.id
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    fetchLibreReasonList() {
      store
        .dispatch('libreReasonStore/fetchLibreReasonLabels')
        .then(response => {
          this.libreReasonList = response.data
        })
        .catch(() => {})
    },

    fetchCareTeamProfList() {
      store
        .dispatch('careTeamStore/fetchCareTeamLabels')
        .then(response => {
          this.careTeamList = response.data
        })
        .catch(() => {})
    },
    fetchHospitalisationList() {
      store
        .dispatch('hospitalisationListStore/fetchHospitalisationListLabels')
        .then(response => {
          this.hospitalisationList = response.data
        })
        .catch(() => {})
    },
    fetchDiabetesComplicationList() {
      store
        .dispatch('diabetesComplicationsStore/fetchDiabetesComplicationLabels')
        .then(response => {
          this.diabetesComplicationsList = response.data
        })
        .catch(() => {})
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    saveCareTeamInfo() {
      this.$refs.refFormCDECareInfo.validate().then(success => {
        if (success) {
          store
            .dispatch('userStore/savePatientCareTeamData', {
              user_pub_id: this.userLocal.user_pub_id,
              care_team_data: this.userLocal,
            })
            .then(() => {
              this.$bvToast.toast(
                'Information has been saved',
                {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                },
              )
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.userLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
