<template>
  <b-card>
    <!-- BODY -->
    <validation-observer
      ref="refFormDiabetesInfo"
    >
      <h5 class="text-capitalize mb-75">
        Diabetes Information
      </h5>
      <!-- form -->
      <b-form
        class="mt-2"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Diabetes Type*"
              label-for="diabetes_type"
            >
              <validation-provider
                #default="{ errors }"
                name="diabetes type"
                rules="required"
              >
                <v-select
                  v-model="userLocal.diabetes_type_data"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="diabetesTypeList"
                  :clearable="false"
                  input-id="diabetes_type"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              userLocal.diabetes_type_data.label ===
                'Other'"
            sm="6"
          >
            <b-form-group
              label="Other Type*"
              label-for="other_diabetes_type"
            >
              <validation-provider
                #default="{ errors }"
                name="other diabetes type"
                rules=""
              >
                <b-form-input
                  id="other_diabetes_type"
                  v-model="assignedDiabetesTypeData.other_diabetes_type"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Please specify other type "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Years since first diagnosis*"
              label-for="years_since_first_diagnosis"
            >
              <validation-provider
                #default="{ errors }"
                name="years since first diagnosis"
                rules="required"
              >
                <v-select
                  v-model="userLocal.years_since_first_diagonsis"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="yearsOptions"
                  :clearable="false"
                  :reduce="val => val.value"
                  input-id="years_since_first_diagnosis"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-2 mr-1"
              @click="saveDiabetesTypeInfo"
            >
              Save Diabetes Information
            </b-button>
          </b-col> -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    // BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      diabetesTypeList: [],
      required,
      alphaNum,
      nameState: null,
      assignedDiabetesTypeData: null,
      defaultSelectedType: {
        label: null,
        value: null,
      },
      yearsOptions: [
        { label: '< 1 Year', value: '< 1 Year' },
        { label: '1-5 Years ago', value: '1-5 Years ago' },
        { label: '6-10 years ago', value: '6-10 years ago' },
        { label: '11-15 years ago', value: '11-15 years ago' },
        { label: '16-20 years ago', value: '16-20 years ago' },
        { label: 'More than 20 years ago', value: 'More than 20 years ago' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
      userLocal: {
        type: Object,
        default: () => {},
      },
    }
  },
  created() {
    this.fetchDiabetesTypeList()
    this.assignedDiabetesTypeData = {}
    if (this.userData.diabetes_data !== null) {
      this.userLocal = this.userData.diabetes_data
    } else {
      this.userLocal = {
        diabetes_type_data: this.defaultSelectedType,
        years_since_first_diagonsis: '',
      }
    }
    this.assignedDiabetesTypeData.user_pub_id = this.userData.id
  },
  methods: {
    validateMandatory() {
      // eslint-disable-next-line consistent-return
      this.$refs.refFormDiabetesInfo.validate().then(success => success)
    },
    saveDiabetesTypeInfo() {
      this.$refs.refFormDiabetesInfo.validate().then(success => {
        if (success) {
          this.assignedDiabetesTypeData.diabetes_type = this.userLocal.diabetes_type_data
          this.assignedDiabetesTypeData.years_since_first_diagnosis = this.userLocal.years_since_first_diagonsis

          if (this.assignedDiabetesTypeData.diabetes_type.value
                !== 'adf7a392-b26b-472e-9529-a0786211e026') {
            this.assignedDiabetesTypeData.other_diabetes_type = null
          }
          store
            .dispatch('userStore/savePatientDiabetesTypeData', this.assignedDiabetesTypeData)
            .then(() => {
              // this.$bvToast.toast('Patient Diabetes information data has been saved', {
              //   title: 'Success',
              //   variant: 'success',
              //   solid: true,
              // })
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
    fetchDiabetesTypeList() {
      store
        .dispatch('diabetesTypeStore/fetchDiabetesTypeLabels')
        .then(response => {
          this.diabetesTypeList = response.data
          if (response.data !== null) {
            this.defaultSelectedType.label = response.data[0].label
            this.defaultSelectedType.value = response.data[0].value
          }
        })
        .catch(() => {})
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.visitLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
