<template>
  <b-card>
    <!-- BODY -->
    <validation-observer
      ref="refFormCDEPatientGlucose"
    >
      <!-- form -->
      <b-form
        class="mt-2"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Current Glucose Monitor *"
              label-for="current_glucose_monitor"
            >
              <validation-provider
                #default="{errors}"
                name="glucose monitor"
                rules="required"
              >
                <v-select
                  v-model="userLocal.glucose_monitor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="glucoseMonitorList"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="current_glucose_monitor"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="
              userLocal.glucose_monitor ===
                'a1147c6a-5505-426e-afea-3227b536e975' ||
                userLocal.glucose_monitor ===
                'da64740e-4726-4caf-9378-dd88afcbef0f'
            "
            sm="6"
          >
            <b-form-group
              label="If BGM or Other please specify*"
              label-for="other_current_glucose_monitor"
            >
              <validation-provider
                #default="{errors}"
                name="other glucose monitor"
                rules="required"
              >
                <b-form-input
                  id="other_current_glucose_monitor"
                  v-model="userLocal.other_current_glucose_monitor"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Please specify if BGM or other type "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="How many strips did you use per day? Before using FreeStyle Libre® *"
              label-for="avg_smbg_test_before_libre"
            >
              <validation-provider
                #default="{errors}"
                name="average smbg test"
                rules="required"
              >
                <v-select
                  v-model="userLocal.avg_smbg_test_before_libre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="numericalOptionList"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="avg_smbg_test_before_libre"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="HbA1c test result - Before starting FreeStyle Libre®"
              label-for="glucose_hba1c_before_libre"
            >
              <validation-provider
                #default="{errors}"
                name="glucose hba1c result"
                rules="required"
              >
                <b-form-input
                  id="glucose_hba1c_before_libre"
                  v-model="userLocal.glucose_hba1c_before_libre"
                  placeholder="Before starting FreeStyle Libre® %"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{errors}"
              name="glucose hba1c date"
              rules="required"
            >
              <b-form-group
                label="Glucose HBA1C Date*"
                label-for="glucose_hba1c_date"
              >
                <b-form-datepicker
                  id="glucose_hba1c_date"
                  v-model="userLocal.glucose_hba1c_date"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormDatepicker,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      glucoseMonitorList: [],
      libreReasonList: [],
      required,
      alphaNum,
      assignedData: null,
      nameState: null,
      patientData: null,
      trSetHeight: null,
      assginedData: {
        type: Object,
        default: () => {},
      },
      numericalOptionList: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: 'Don\'t know/Depends', value: 'Don\'t know/Depends' },
      ],
      NdssApprovedOptions: [
        { label: 'T1 < 21 years', value: 'T1 < 21 years' },
        {
          label: 'T1 > 21 years + concession',
          value: 'T1 > 21 years + concession',
        },
        {
          label: 'T1 planning pregnancy/pregnant/post pregnancy',
          value: 'T1 planning pregnancy/pregnant/post pregnancy',
        },
        {
          label: 'Other diabetes > 21 years',
          value: 'Other diabetes > 21 years',
        },
      ],
      userLocal: {
        type: Object,
        default: () => {},
      },
    }
  },
  created() {
    this.fetchGlucoseMonitorList()
    if (this.userData.glucose_libre_data !== null) {
      this.userLocal = this.userData.glucose_libre_data
    } else {
      this.userLocal = {
        glucose_libre_data: {
          glucose_monitor: {
            pub_id: '',
            name: '',
          },
        },
      }
    }
    this.userLocal.user_pub_id = this.userData.id
  },
  methods: {
    fetchGlucoseMonitorList() {
      store
        .dispatch('glucoseMonitorStore/fetchGlucoseMonitorLabels')
        .then(response => {
          this.glucoseMonitorList = response.data
        })
        .catch(() => {})
    },

    saveGlucoseMonitorInfo() {
      // this.assignedData.diabetes_type = this.userData.diabetes_type_data.pub_id
      // this.assignedData.years_since_first_diagnosis = this.userData.diabetes_type_data.years_since_first_diagonsis
      // if (this.userData.diabetes_type_data.diabetes_type_data.pub_id
      //           !== 'a1147c6a-5505-426e-afea-3227b536e975') {
      //   this.assignedData.other_diabetes_type = null
      // }
      this.$refs.refFormCDEPatientGlucose.validate().then(success => {
        if (success) {
          store
            .dispatch('userStore/savePatientGlucoseLibreData', this.userLocal)
            .then(() => {
              // this.$bvToast.toast(
              //   'Patient Glucose information data has been saved',
              //   {
              //     title: 'Success',
              //     variant: 'success',
              //     solid: true,
              //   },
              // )
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.assignedData.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
