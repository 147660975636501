var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"refFormCDEPatientGlucose"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Current Glucose Monitor *","label-for":"current_glucose_monitor"}},[_c('validation-provider',{attrs:{"name":"glucose monitor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.glucoseMonitorList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"current_glucose_monitor","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.glucose_monitor),callback:function ($$v) {_vm.$set(_vm.userLocal, "glucose_monitor", $$v)},expression:"userLocal.glucose_monitor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
            _vm.userLocal.glucose_monitor ===
              'a1147c6a-5505-426e-afea-3227b536e975' ||
              _vm.userLocal.glucose_monitor ===
              'da64740e-4726-4caf-9378-dd88afcbef0f'
          )?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"If BGM or Other please specify*","label-for":"other_current_glucose_monitor"}},[_c('validation-provider',{attrs:{"name":"other glucose monitor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_current_glucose_monitor","state":errors.length > 0 ? false:null,"placeholder":"Please specify if BGM or other type "},model:{value:(_vm.userLocal.other_current_glucose_monitor),callback:function ($$v) {_vm.$set(_vm.userLocal, "other_current_glucose_monitor", $$v)},expression:"userLocal.other_current_glucose_monitor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4179197153)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"How many strips did you use per day? Before using FreeStyle Libre® *","label-for":"avg_smbg_test_before_libre"}},[_c('validation-provider',{attrs:{"name":"average smbg test","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.numericalOptionList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"avg_smbg_test_before_libre","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.avg_smbg_test_before_libre),callback:function ($$v) {_vm.$set(_vm.userLocal, "avg_smbg_test_before_libre", $$v)},expression:"userLocal.avg_smbg_test_before_libre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"HbA1c test result - Before starting FreeStyle Libre®","label-for":"glucose_hba1c_before_libre"}},[_c('validation-provider',{attrs:{"name":"glucose hba1c result","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"glucose_hba1c_before_libre","placeholder":"Before starting FreeStyle Libre® %","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.glucose_hba1c_before_libre),callback:function ($$v) {_vm.$set(_vm.userLocal, "glucose_hba1c_before_libre", $$v)},expression:"userLocal.glucose_hba1c_before_libre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"glucose hba1c date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Glucose HBA1C Date*","label-for":"glucose_hba1c_date"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"glucose_hba1c_date","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.glucose_hba1c_date),callback:function ($$v) {_vm.$set(_vm.userLocal, "glucose_hba1c_date", $$v)},expression:"userLocal.glucose_hba1c_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }