<template>
  <b-card>
    <!-- about -->
    <h3>Pharmacy Details </h3>
    <div
      class="mt-2"
    >
      <h5 class="text-capitalize mb-75">
        Name
      </h5>
      <b-card-text>
        {{ aboutData.name }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        Address
      </h5>
      <b-card-text>
        {{ aboutData.address }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
