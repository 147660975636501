var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h5',{staticClass:"text-capitalize mb-75"},[_vm._v(" Insulins ")]),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"repeater-form mt-2",style:({ height: _vm.trHeight })},_vm._l((_vm.patientPreAssignedInsulinList),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Insulin*","label-for":"insulins"}},[_c('validation-provider',{attrs:{"name":"insulins","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.insulinList,"clearable":false,"input-id":"added_by","aria-required":"true","state":errors.length > 0 ? false:null},model:{value:(item.insulins),callback:function ($$v) {_vm.$set(item, "insulins", $$v)},expression:"item.insulins"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"1"}},[_c('b-form-group',{attrs:{"label":"Unit/Dose*","label-for":"insulin_unit_per_dose"}},[_c('validation-provider',{attrs:{"name":"unit/dose","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"insulin_unit_per_dose","placeholder":"Put in Units and Dosage e.g. 20 units n","state":errors.length > 0 ? false:null},model:{value:(item.insulin_unit_per_dose),callback:function ($$v) {_vm.$set(item, "insulin_unit_per_dose", $$v)},expression:"item.insulin_unit_per_dose"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"1"}},[_c('b-form-group',{attrs:{"label":"Strength*","label-for":"insulin_medicine_strength"}},[_c('validation-provider',{attrs:{"name":"strength","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"insulin_medicine_strength","required":"","placeholder":"","state":errors.length > 0 ? false:null},model:{value:(item.strength),callback:function ($$v) {_vm.$set(item, "strength", $$v)},expression:"item.strength"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"1"}},[_c('b-form-group',{attrs:{"label":"Directions*","label-for":"medicine_direction"}},[_c('validation-provider',{attrs:{"name":"directions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"medicine_direction","placeholder":"","state":errors.length > 0 ? false:null},model:{value:(item.directions),callback:function ($$v) {_vm.$set(item, "directions", $$v)},expression:"item.directions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"1","md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeItem(index, item.id)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1)],1)],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1),_c('b-card',[_c('h5',{staticClass:"text-capitalize mb-75"},[_vm._v(" Insulin Pump ")]),_c('validation-observer',{ref:"refFormCDEPatientInsulin"},[_c('b-form',{staticClass:"repeater-form mt-2",style:({ height: _vm.trHeight })},[_c('b-row',[(_vm.insulin_pump.label == 'Other')?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"If Other please specify","label-for":"other_insulin_pump"}},[_c('validation-provider',{attrs:{"name":"other insulin pumps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_insulin_pump","placeholder":"Please specify other type ","state":errors.length > 0 ? false:null},model:{value:(_vm.other_insulin_pump),callback:function ($$v) {_vm.other_insulin_pump=$$v},expression:"other_insulin_pump"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1668432413)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"","label-for":"insulin_pumps"}},[_c('validation-provider',{attrs:{"name":"insulin pumps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","aria-required":"true","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.insulinPumpList,"clearable":false,"input-id":"insulin_pump","state":errors.length > 0 ? false:null},model:{value:(_vm.insulin_pump),callback:function ($$v) {_vm.insulin_pump=$$v},expression:"insulin_pump"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }