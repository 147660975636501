var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"refFormDiabetesInfo"},[_c('h5',{staticClass:"text-capitalize mb-75"},[_vm._v(" Diabetes Information ")]),_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Diabetes Type*","label-for":"diabetes_type"}},[_c('validation-provider',{attrs:{"name":"diabetes type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.diabetesTypeList,"clearable":false,"input-id":"diabetes_type","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.diabetes_type_data),callback:function ($$v) {_vm.$set(_vm.userLocal, "diabetes_type_data", $$v)},expression:"userLocal.diabetes_type_data"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
            _vm.userLocal.diabetes_type_data.label ===
              'Other')?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Other Type*","label-for":"other_diabetes_type"}},[_c('validation-provider',{attrs:{"name":"other diabetes type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_diabetes_type","state":errors.length > 0 ? false:null,"placeholder":"Please specify other type "},model:{value:(_vm.assignedDiabetesTypeData.other_diabetes_type),callback:function ($$v) {_vm.$set(_vm.assignedDiabetesTypeData, "other_diabetes_type", $$v)},expression:"assignedDiabetesTypeData.other_diabetes_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2875429990)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Years since first diagnosis*","label-for":"years_since_first_diagnosis"}},[_c('validation-provider',{attrs:{"name":"years since first diagnosis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.yearsOptions,"clearable":false,"reduce":function (val) { return val.value; },"input-id":"years_since_first_diagnosis","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.years_since_first_diagonsis),callback:function ($$v) {_vm.$set(_vm.userLocal, "years_since_first_diagonsis", $$v)},expression:"userLocal.years_since_first_diagonsis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }