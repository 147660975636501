var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h5',{staticClass:"text-capitalize mb-75"},[_vm._v(" Hospitalisation and Complication Information ")]),_c('validation-observer',{ref:"refFormCDECareInfo"},[_c('b-form',{staticClass:"repeater-form mt-2",style:({ height: _vm.trHeight })},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"In the last 3 months did you see an Endocrinologist or Credentialled Diabetes Educator?*","label-for":"care_team_visit"}},[_c('validation-provider',{attrs:{"name":"team visit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.careTeamList,"clearable":false,"input-id":"added_by","aria-required":"true","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.care_team),callback:function ($$v) {_vm.$set(_vm.userLocal, "care_team", $$v)},expression:"userLocal.care_team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"Diabetes Complications before FreeStyle Libre®?","label-for":"diabetes_complications"}},[_c('v-select',{attrs:{"aria-required":"true","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.diabetesComplicationsList,"clearable":false,"multiple":"","input-id":"diabetes_complications"},model:{value:(_vm.userLocal.diabetes_complications),callback:function ($$v) {_vm.$set(_vm.userLocal, "diabetes_complications", $$v)},expression:"userLocal.diabetes_complications"}})],1)],1)],1),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"sm":"10"}},[_c('b-form-group',{attrs:{"label":"Number of Hospitalisations - Before using FreeStyle Libre® *","label-for":"number_of_hospitalisation"}},[_c('validation-provider',{attrs:{"name":"number of hospitalisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.numericalOptionList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"number_of_hospitalisation","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.number_of_hospitalisation),callback:function ($$v) {_vm.$set(_vm.userLocal, "number_of_hospitalisation", $$v)},expression:"userLocal.number_of_hospitalisation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"Hospitalisation Details","label-for":"hospitalisation_details"}},[_c('v-select',{attrs:{"multiple":"","aria-required":"true","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.hospitalisationList,"clearable":false,"input-id":"hospitalisation_details"},model:{value:(_vm.userLocal.hospitalisation_details),callback:function ($$v) {_vm.$set(_vm.userLocal, "hospitalisation_details", $$v)},expression:"userLocal.hospitalisation_details"}})],1)],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"Hospitalisation Detail Comments","label-for":"hospitalisation_details_comments"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Add any comments regarding hospitalisation","rows":"3"},model:{value:(_vm.userLocal.hospitalisation_details_comments),callback:function ($$v) {_vm.$set(_vm.userLocal, "hospitalisation_details_comments", $$v)},expression:"userLocal.hospitalisation_details_comments"}})],1)],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"Recent changes in diabetes therapy*","label-for":"recent_changes_in_diabetes_therapy"}},[_c('validation-provider',{attrs:{"name":"recent changes in diabetes therapy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"","rows":"3","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.recent_changes_in_diabetes_therapy),callback:function ($$v) {_vm.$set(_vm.userLocal, "recent_changes_in_diabetes_therapy", $$v)},expression:"userLocal.recent_changes_in_diabetes_therapy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"Patient Comments","label-for":"patient_comments"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"","rows":"3"},model:{value:(_vm.userLocal.patient_comments),callback:function ($$v) {_vm.$set(_vm.userLocal, "patient_comments", $$v)},expression:"userLocal.patient_comments"}})],1)],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{attrs:{"label":"CDE Comments","label-for":"cde_comments"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"","rows":"3"},model:{value:(_vm.userLocal.healthcare_professional_comments),callback:function ($$v) {_vm.$set(_vm.userLocal, "healthcare_professional_comments", $$v)},expression:"userLocal.healthcare_professional_comments"}})],1)],1)],1)],1)],1)],1),_c('b-card',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"repeater-form mt-2",style:({ height: _vm.trHeight })},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Is FreeStyle Libre 2 recommended? If so, what NDSS form was completed and sent? *","label-for":"if_freestyle_libre_ndss_approved"}},[_c('validation-provider',{attrs:{"name":"ndss_approved_criteria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.NdssApprovedOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"ndss_approved_criteria","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.ndss_approved_criteria),callback:function ($$v) {_vm.$set(_vm.userLocal, "ndss_approved_criteria", $$v)},expression:"userLocal.ndss_approved_criteria"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Reason for recommending FreeStyle Libre® *","label-for":"freestyle_libre_reason"}},[_c('validation-provider',{attrs:{"name":"Reason for recommending FreeStyle Libre®","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.libreReasonList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"freestyle_libre_reason","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.freestyle_libre_reason),callback:function ($$v) {_vm.$set(_vm.userLocal, "freestyle_libre_reason", $$v)},expression:"userLocal.freestyle_libre_reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
              _vm.userLocal.freestyle_libre_reason ===
                'b3f1518d-5b2a-431e-a50d-90d34be3f693'
            )?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Please specify a reason if Other *","label-for":"other_freestyle_libre_reason"}},[_c('validation-provider',{attrs:{"name":"other reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_freestyle_libre_reason","placeholder":"Please specify a reason for recommending FreeStyle Libre®","state":errors.length > 0 ? false:null},model:{value:(_vm.userLocal.other_freestyle_libre_reason),callback:function ($$v) {_vm.$set(_vm.userLocal, "other_freestyle_libre_reason", $$v)},expression:"userLocal.other_freestyle_libre_reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1279395344)})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }