<template>
  <b-card>
    <h5 class="text-capitalize mb-75">
      Medication Information
    </h5>
    <!-- BODY -->
    <validation-observer
      ref="refFormPatientMedication"
    >
      <!-- form -->
      <b-form
        :style="{ height: trHeight }"
        class="repeater-form mt-2"
      >
        <b-row
          v-for="(item, index) in patientPreAssignedMedicineList"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col md="3">
            <b-form-group
              label="Medication*"
              label-for="diabetes_medicine"
            >
              <validation-provider
                #default="{errors}"
                name="diabetes medicine"
                rules="required"
              >
                <v-select
                  v-model="item.diabetes_medicine"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="diabetesMedicineList"
                  :clearable="false"
                  input-id="added_by"
                  aria-required="true"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Brand*"
              label-for="diabetes_medicine_brand"
            >
              <validation-provider
                #default="{errors}"
                name="brand"
                rules="required"
              >
                <v-select
                  v-model="item.diabetes_medicine_brand"
                  aria-required="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="diabetesMedicineBrandList"
                  :clearable="false"
                  input-id="diabetes_medicine_brand"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Strength*"
              label-for="diabetes_medicine_strength"
            >
              <validation-provider
                #default="{errors}"
                name="strength"
                rules="required"
              >
                <b-form-input
                  id="diabetes_medicine_strength"
                  v-model="item.strength"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Form*"
              label-for="diabetes_medicine_form"
            >
              <validation-provider
                #default="{errors}"
                name="form"
                rules="required"
              >
                <b-form-input
                  id="diabetes_medicine_form"
                  v-model="item.form"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Directions*"
              label-for="medicine_direction"
            >
              <validation-provider
                #default="{errors}"
                name="strength"
                rules="required"
              >
                <b-form-input
                  id="medicine_direction"
                  v-model="item.directions"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="1"
            md="1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mr-1"
              @click="removeItem(index, item.id)"
            >
              <feather-icon icon="ArchiveIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      profileFile: null,
      patientPreAssignedMedicineList: [],
      medicationSelected: [],
      medicationBrandSelected: [],
      diabetesMedicineList: [],
      diabetesMedicineBrandList: [],
      required,
      alphaNum,
      nameState: null,
      patientData: null,
      visitLocal: {
        type: Object,
        default: () => {},
      },
      // items: [{
      //   id: 1,
      //   selected: 'male',
      //   selected1: 'designer',
      //   prevHeight: 0,
      // }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetchPreAssignedMedicationList()
    this.fetchDiabetesMedicineList()
    this.fetchDiabetesMedicineBrandList()
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    fetchDiabetesMedicineList() {
      store
        .dispatch('diabetesMedicineStore/fetchDiabetesMedicineLabels')
        .then(response => {
          this.diabetesMedicineList = response.data
        })
        .catch(() => {})
    },
    fetchDiabetesMedicineBrandList() {
      store
        .dispatch('diabetesMedicineBrandStore/fetchDiabetesMedicineBrandLabels')
        .then(response => {
          this.diabetesMedicineBrandList = response.data
        })
        .catch(() => {})
    },
    fetchPreAssignedMedicationList() {
      store
        .dispatch('userStore/fetchPreAssignedMedicationList', {
          userId: this.userId,
        })
        .then(response => {
          this.patientPreAssignedMedicineList = response.data
          this.nextTodoId = this.patientPreAssignedMedicineList.length + 1
        })
        .catch(() => {})
    },
    repeateAgain() {
      this.patientPreAssignedMedicineList.push({
        id: (this.nextTodoId += this.nextTodoId),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index, medicineDataPubID) {
      this.deleteMedicationItem(index, medicineDataPubID)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    saveMedications() {
      this.$refs.refFormPatientMedication.validate().then(success => {
        if (success) {
          store
            .dispatch('userStore/savePatientMedicationData', {
              user_pub_id: this.userId,
              user_medications: this.patientPreAssignedMedicineList,
            })
            .then(() => {
              // this.$bvToast.toast('Medication data has been saved', {
              //   title: 'Success',
              //   variant: 'success',
              //   solid: true,
              // })
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
    deleteMedicationItem(index, medicineDataPubID) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete this medication.',
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value === true) {
            store
              .dispatch('userStore/removePatientMedicationDataItem', {
                medicationDataItemID: medicineDataPubID,
              })
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.$bvToast.toast(
                  'We have remove the medication successfully.',
                  {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                  },
                )
              })
              .catch(() => {
                this.$bvToast.toast(
                  'We could not process your action this time. please try again.',
                  {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  },
                )
              })

            this.patientPreAssignedMedicineList.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          }
          // console.log(`${value} ${id}`)
        })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.visitLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
