/* eslint-disable no-unused-expressions */
<template>
  <b-overlay
    :show="isSaving"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div id="patient-details">
      <section id="profile-info">
        <b-row>
          <!-- about suggested page and twitter feed -->
          <b-col
            lg="3"
            cols="12"
            order="2"
            order-lg="1"
          >
            <pharmacy-info :about-data="patientPharmacyData" />
            <patient-info :about-data="patientData" />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-2 mr-1"
              @click.prevent="saveAll"
            >
              Save Details
            </b-button>
          </b-col>
          <b-col
            lg="9"
            cols="12"
            order="1"
            order-lg="2"
          >
            <patient-general-view
              ref="patient_general_view"
              :patient-data="patientData"
            />
            <patient-diabetes-info
              ref="patient_diabetes_info"
              :user-data="patientData"
            />

            <patient-glucose-monitor
              ref="patient_glucose_monitor"
              :user-data="patientData"
            />
            <patient-diabetes-medicine
              ref="patient_diabetes_medicine"
              :user-id="patientData.id"
            />

            <patient-insulin
              ref="patient_diabetes_insulin"
              :user-id="patientData.id"
            />
            <patient-care-team-info
              ref="patient_care_team_info"
              :user-data="patientData"
            />
            <b-card>
              <b-col md="10">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-2 mr-1"
                  @click.prevent="saveAll"
                >
                  Save Details
                </b-button>
              </b-col>
            </b-card>

          </b-col>
        </b-row>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'

import PharmacyInfo from '@/views/apps/pharmacy/PharmacyInfo.vue'
import PatientInfo from '@/views/apps/user/patient-data-view/PatientInfoSmall.vue'
// import PatientMedicationInfo from '@/views/apps/user/PatientMedicationInfo.vue'
import PatientDiabetesInfo from '@/views/apps/user/patient-data-view/Patient-DiabetesInfoView.vue'
import PatientGlucoseMonitor from '@/views/apps/user/patient-data-view/Patient-GlucoseMonitorView.vue'
import PatientCareTeamInfo from '@/views/apps/user/patient-data-view/Patient-CareTeamInfo.vue'
import PatientInsulin from '@/views/apps/user/patient-data-view/Patient-InsulinListView.vue'
import PatientDiabetesMedicine from '@/views/apps/user/patient-data-view/Patient-MedicationListView.vue'
import Ripple from 'vue-ripple-directive'
import PatientGeneralView from './Patient-GeneralView.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    PatientInfo,
    PatientGeneralView,
    PatientDiabetesInfo,
    PatientGlucoseMonitor,
    PatientCareTeamInfo,
    PatientInsulin,
    PatientDiabetesMedicine,
    PharmacyInfo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},
      userData: null,
      patientData: null,
      patientPharmacyData: null,
      spinnerShow: true,
      visitCount: 0,
      isSaving: false,
    }
  },
  computed: {
    currentVisit() {
      return this.visitCount + 1
    },
  },
  created() {
    this.fetchPatientInfo()
    this.spinnerShow = false
  },
  methods: {
    fetchPatientInfo() {
      store
        .dispatch('userStore/fetchPatientInfo', {
          userId: router.currentRoute.params.id,
        })
        .then(response => {
          this.patientData = response.data
          this.patientPharmacyData = response.data.pharmacy
        })
        // .catch(error => {
        //   console.info(error)
        // })
        .catch()
    },
    async saveAll() {
      await this.$refs.patient_general_view.savePatientGeneralInfo()
      await this.$refs.patient_diabetes_info.saveDiabetesTypeInfo()
      await this.$refs.patient_glucose_monitor.saveGlucoseMonitorInfo()
      await this.$refs.patient_diabetes_insulin.savePatientInsulins()
      await this.$refs.patient_diabetes_medicine.saveMedications()
      await this.$refs.patient_care_team_info.saveCareTeamInfo()
    },
  },
}
</script>
