<template>
  <b-card>
    <!-- about -->
    <h3>Patient Details</h3>
    <div
      class="mt-2"
    >
      <h5 class="text-capitalize mb-75">
        Name
      </h5>
      <b-card-text>
        {{ aboutData.name }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        Contact
      </h5>
      <b-card-text>
        DOB:  {{ aboutData.dob }}<br>
        Email:  {{ aboutData.email }}<br>
        Phone:  {{ aboutData.phone }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
