<template>
  <div>
    <b-card>
      <h5 class="text-capitalize mb-75">
        Insulins
      </h5>
      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- form -->
        <b-form
          :style="{ height: trHeight }"
          class="repeater-form mt-2"
        >
          <b-row
            v-for="(item, index) in patientPreAssignedInsulinList"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="4">
              <b-form-group
                label="Insulin*"
                label-for="insulins"
              >
                <validation-provider
                  #default="{errors}"
                  name="insulins"
                  rules="required"
                >
                  <v-select
                    v-model="item.insulins"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="insulinList"
                    :clearable="false"
                    input-id="added_by"
                    aria-required="true"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="2"
              md="1"
            >
              <b-form-group
                label="Unit/Dose*"
                label-for="insulin_unit_per_dose"
              >
                <validation-provider
                  #default="{errors}"
                  name="unit/dose"
                  rules="required"
                >
                  <b-form-input
                    id="insulin_unit_per_dose"
                    v-model="item.insulin_unit_per_dose"
                    placeholder="Put in Units and Dosage e.g. 20 units n"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="2"
              md="1"
            >
              <b-form-group
                label="Strength*"
                label-for="insulin_medicine_strength"
              >
                <validation-provider
                  #default="{errors}"
                  name="strength"
                  rules="required"
                >
                  <b-form-input
                    id="insulin_medicine_strength"
                    v-model="item.strength"
                    required
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col
              lg="1"
              md="1"
            >
              <b-form-group
                label="Form*"
                label-for="insulin_medicine_form"
              >
                <validation-provider
                  #default="{errors}"
                  name="form"
                  rules="required"
                >
                  <b-form-input
                    id="insulin_medicine_form"
                    v-model="item.form"
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col
              lg="3"
              md="1"
            >
              <b-form-group
                label="Directions*"
                label-for="medicine_direction"
              >
                <validation-provider
                  #default="{errors}"
                  name="directions"
                  rules="required"
                >
                  <b-form-input
                    id="medicine_direction"
                    v-model="item.directions"
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="1"
              md="1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mt-2 mr-1"
                @click="removeItem(index, item.id)"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button>
      </validation-observer>
    </b-card>
    <b-card>
      <h5 class="text-capitalize mb-75">
        Insulin Pump
      </h5>
      <!-- BODY -->
      <validation-observer
        ref="refFormCDEPatientInsulin"
      >
        <!-- form -->
        <b-form
          :style="{ height: trHeight }"
          class="repeater-form mt-2"
        >
          <b-row>
            <b-col
              v-if="insulin_pump.label == 'Other'"
              sm="6"
            >
              <b-form-group
                label="If Other please specify"
                label-for="other_insulin_pump"
              >
                <validation-provider
                  #default="{errors}"
                  name="other insulin pumps"
                  rules="required"
                >
                  <b-form-input
                    id="other_insulin_pump"
                    v-model="other_insulin_pump"

                    placeholder="Please specify other type "
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label=""
                label-for="insulin_pumps"
              >
                <validation-provider
                  #default="{errors}"
                  name="insulin pumps"
                  rules="required"
                >
                  <v-select
                    v-model="insulin_pump"
                    required
                    aria-required="true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="insulinPumpList"
                    :clearable="false"
                    input-id="insulin_pump"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      profileFile: null,
      patientPreAssignedInsulinList: [],
      insulinSelected: [],
      insulinBrandSelected: [],
      insulinList: [],
      insulinPumpList: [],
      required,
      alphaNum,
      nameState: null,
      patientData: null,
      insulin_pump: { label: null, value: null },
      other_insulin_pump: null,
      visitLocal: {
        type: Object,
        default: () => {},
      },
      // items: [{
      //   id: 1,
      //   selected: 'male',
      //   selected1: 'designer',
      //   prevHeight: 0,
      // }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetchPreAssignedInsulinList()
    this.fetchInsulinList()
    this.fetchInsulinPumpList()
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    fetchInsulinList() {
      store
        .dispatch('insulinStore/fetchInsulinLabels')
        .then(response => {
          this.insulinList = response.data
        })
        .catch(() => {})
    },
    fetchInsulinPumpList() {
      store
        .dispatch('insulinPumpStore/fetchInsulinPumpLabels')
        .then(response => {
          this.insulinPumpList = response.data
        })
        .catch(() => {})
    },
    fetchPreAssignedInsulinList() {
      store
        .dispatch('userStore/fetchPreAssignedInsulinList', {
          userId: this.userId,
        })
        .then(response => {
          this.patientPreAssignedInsulinList = response.data
          this.nextTodoId = this.patientPreAssignedInsulinList.length + 1
          this.insulin_pump = this.patientPreAssignedInsulinList[0].insulin_pumps // all are same in this case
          if (this.insulin_pump === null) {
            // select the default one
            this.insulin_pump = { value: 'c4d68b68-a2cd-487c-aae7-5b78ca897f9b', label: 'Not Applicable' }
          }
        })
        .catch(() => {})
    },
    repeateAgain() {
      this.patientPreAssignedInsulinList.push({
        id: (this.nextTodoId += this.nextTodoId),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index, insulinDataPubID) {
      this.deleteInsulinItem(index, insulinDataPubID)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    savePatientInsulins() {
      this.$refs.refFormCDEPatientInsulin.validate().then(success => {
        if (success) {
          if (this.insulin_pump.value !== '7645d730-261a-4b30-9c21-5b491fcc2cab') {
            this.other_insulin_pump = null
          }
          store
            .dispatch('userStore/savePatientInsulinData', {
              user_pub_id: this.userId,
              user_insulins: this.patientPreAssignedInsulinList,
              insulin_pump_selected: this.insulin_pump,
              other_insulin_pump: this.other_insulin_pump,
            })
            .then(() => {
              // this.$bvToast.toast('Insulin data has been saved', {
              //   title: 'Success',
              //   variant: 'success',
              //   solid: true,
              // })
            })
            .catch(() => {
              this.$bvToast.toast(
                'We could not process your action this time. please try again.',
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                },
              )
            })
        }
      })
    },
    deleteInsulinItem(index, insulinDataPubID) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this insulin.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch('userStore/removePatientInsulinDataItem', {
                insulinDataItemID: insulinDataPubID,
              })
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.$bvToast.toast(
                  'We have remove the insulin successfully.',
                  {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                  },
                )
              })
              .catch(() => {
                this.$bvToast.toast(
                  'We could not process your action this time. please try again.',
                  {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  },
                )
              })

            this.patientPreAssignedInsulinList.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          }
          // console.log(`${value} ${id}`)
        })
    },
  },
  setup() {
    const blankData = {
      name: '',
      abbr: '',
    }

    const resetData = () => {
      this.visitLocal.value = JSON.parse(JSON.stringify(blankData))
      this.nameState = null
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
