var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h5',{staticClass:"text-capitalize mb-75"},[_vm._v(" Medication Information ")]),_c('validation-observer',{ref:"refFormPatientMedication"},[_c('b-form',{staticClass:"repeater-form mt-2",style:({ height: _vm.trHeight })},_vm._l((_vm.patientPreAssignedMedicineList),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Medication*","label-for":"diabetes_medicine"}},[_c('validation-provider',{attrs:{"name":"diabetes medicine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.diabetesMedicineList,"clearable":false,"input-id":"added_by","aria-required":"true","state":errors.length > 0 ? false:null},model:{value:(item.diabetes_medicine),callback:function ($$v) {_vm.$set(item, "diabetes_medicine", $$v)},expression:"item.diabetes_medicine"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Brand*","label-for":"diabetes_medicine_brand"}},[_c('validation-provider',{attrs:{"name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"aria-required":"true","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.diabetesMedicineBrandList,"clearable":false,"input-id":"diabetes_medicine_brand","state":errors.length > 0 ? false:null},model:{value:(item.diabetes_medicine_brand),callback:function ($$v) {_vm.$set(item, "diabetes_medicine_brand", $$v)},expression:"item.diabetes_medicine_brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"1"}},[_c('b-form-group',{attrs:{"label":"Strength*","label-for":"diabetes_medicine_strength"}},[_c('validation-provider',{attrs:{"name":"strength","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"diabetes_medicine_strength","placeholder":"","state":errors.length > 0 ? false:null},model:{value:(item.strength),callback:function ($$v) {_vm.$set(item, "strength", $$v)},expression:"item.strength"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"1"}},[_c('b-form-group',{attrs:{"label":"Form*","label-for":"diabetes_medicine_form"}},[_c('validation-provider',{attrs:{"name":"form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"diabetes_medicine_form","placeholder":"","state":errors.length > 0 ? false:null},model:{value:(item.form),callback:function ($$v) {_vm.$set(item, "form", $$v)},expression:"item.form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"1"}},[_c('b-form-group',{attrs:{"label":"Directions*","label-for":"medicine_direction"}},[_c('validation-provider',{attrs:{"name":"strength","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"medicine_direction","placeholder":"","state":errors.length > 0 ? false:null},model:{value:(item.directions),callback:function ($$v) {_vm.$set(item, "directions", $$v)},expression:"item.directions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"1","md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeItem(index, item.id)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1)],1)],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }